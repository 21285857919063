import React, { Component } from "react";

class Contact extends Component {
  state = {};
  render() {
    return <h1>Contact</h1>;
  }
}

export default Contact;
