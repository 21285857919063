import React, { Component } from "react";
import "./qna-style.css";
import TopicList from "./TopicList";


class QnA extends Component {
  state = {};



  render() {
    return (
      <>


        <TopicList />



      </>);
  }
}

export default QnA;
