import React, { Component } from "react";

class About extends Component {
  state = {};
  render() {
    return <h1>About</h1>;
  }
}

export default About;
